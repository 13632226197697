import React from 'react'
import styled from '@emotion/styled'
import SanityRichText from 'src/components/SanityRichText'
import { typography } from 'src/styles'
import ThemeSelector from 'src/components/ThemeSelector'

const Wrapper = styled(ThemeSelector)`
	${ typography.bodySmall }
	font-weight: ${ typography.medium };
	padding: .5em 1em .6em;
	border-radius: 3px;
	b {
		${ typography.smallCaps }
	}
	p {
		margin: 0;
		${ typography.bodySmall }
		font-weight: ${ typography.medium };
		line-height: 1.5em;
	}

`

const MediaCaption = ({ className, text, setTheme }) => {
	if (!text) {
		return false
	}
	return (
		<Wrapper className={className} setTheme={setTheme}>
			<SanityRichText text={text} debug />
		</Wrapper>
	)
}

export default MediaCaption
