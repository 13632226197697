import React from 'react'
import { rgba } from 'polished'
import styled from '@emotion/styled'
import ReactModal from 'react-modal'
import { colors } from 'src/styles'
import Helmet from 'react-helmet'

const timeout = 300

const Wrapper = styled(ReactModal)``

const modalStyles = (isOpen) => ({
	content: {
		top: null,
		left: null,
		right: null,
		bottom: null,
		border: null,
		overflow: null,
		WebkitOverflowScrolling: null,
		borderRadius: null,
		background: colors.bgColor,
		position: 'relative',
		// transition: `opacity ${timeout}ms ease-in-out`,
		margin: 'auto',
		// opacity: isOpen ? 1 : 0
	},
	overlay: {
		zIndex: 5,
		position: 'fixed',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		pointerEvents: 'click',
		overflow: 'auto',
		background: rgba(colors.textColor, 0.2),
		transition: `opacity ${timeout}ms ease-in-out`,
		opacity: isOpen ? 1 : 0,
		padding: '5vw 0'
	},
})

const Modal = ({ className, children, isOpen, ...rest }) => {
	return (
		<>
			<Helmet>
				{isOpen && (
					<style type="text/css">
						{`
			        body {
			          overflow: hidden;
			        }
				    `}
			    </style>
		    )}
			</Helmet>
			<Wrapper
				style={modalStyles(isOpen)}
				className={className}
				shouldCloseOnOverlayClick={true}
				isOpen={isOpen}
				closeTimeoutMS={timeout}
				{...rest}
			>
				{children}
			</Wrapper>
		</>
	)
}

export default Modal
