import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import styled from '@emotion/styled'
import ReCAPTCHA from 'react-google-recaptcha'
import { mq, globals } from 'src/styles'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Input from 'src/components/Input'
import TextArea from 'src/components/TextArea'
import Select from 'src/components/Select'
import Button from 'src/components/Button'
import { validateEmail, validatePhoneNumber } from 'src/utils/validations'

const formSpacing = '14px'
const FormSection = styled(Section)`
  ${mq.largeAndBelow} {
    ${ globals.verticalSpacing('padding-bottom') }
  }
`
const url = `https://cpp-smartsheet-qgle2i7kfly3.runkit.sh/`

const SmartsheetForm = ({
  className,
  nextTheme,
  prevTheme,
  theme,
  title,
  isFirstSection,
  sheetId,
  id
}) => {
  const [fields, setFields] = useState([])
  const [fieldData, setFieldData] = useState({})
  const [status, setStatus] = useState()
  const [valid, setValid] = useState(true)
  useEffect(() => {
		if (typeof window !== "undefined" && sheetId) {
			fetch(`${url}${sheetId}`)
				.then((response) => response.json())
				.then((json) => {
          const { data } = json
          setFields(data)
				})
		}
	}, [])

  const handleInput = e => {
    const { name, value } = e.target
    setFieldData({ ...fieldData, [name]: value })
  }

  const submit = e => {
    e.preventDefault()
    const payload = {
      "cells": Object.keys(fieldData).map(columnId => ({
        columnId,
        value: fieldData[columnId],
      })),
    }
    fetch(`${url}${sheetId}?${qs.stringify(payload)}`, { method: 'POST' })
      .then((response) => response.json())
      .then((json) => {
        const { message } = json
        setStatus(message)
        setTimeout(() => {
          setFieldData({})
          window.location.href = "/thank-you"
        }, 1000)
      })
  }

  const recaptchaRef = React.createRef()

  return (
    <FormSection
      className={className}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      setTheme={theme}
      isFirstSection={isFirstSection}
    >
      <Grid small='container' extraLarge='2 [10] 2'>
        <Grid small='[1]' large='[4] [8]' vAlign='top' as={ScrollEntrance} rowGap='24px'>
          <div>
            <h1 style={{ margin: 0 }}>{title || 'Contact Us'}</h1>
          </div>
          <div>
            <form
              onSubmit={submit}
              action=""
              method="POST"
            >
              <Grid small='[1]' rowGap={formSpacing}>
                {(fields || []).map(({ id, title, type, options, validation }) => {
                  if (type === 'PICKLIST') return (
                    <Select
                      label={title}
                      name={id}
                      onChange={handleInput}
                      value={fieldData[id] || ''}
                      options={options.map(value => ({ label: value, value }))}
                    />
                  )
                  return (
                    <div>
                      <Input
                        onChange={handleInput}
                        name={id}
                        value={fieldData[id]}
                        label={title}
                        {...title.toLowerCase().includes('email') && {
                          type: 'email',
                          isValid: fieldData[id] !== '' ? validateEmail(fieldData[id]) : true,
                        }}
                        {...title.toLowerCase().includes('phone') && {
                          type: 'tel',
                          isValid: fieldData[id] !== '' ? validatePhoneNumber(fieldData[id]) : true,
                        }}
                      />
                    </div>
                  )
                })}
                <div>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LfuGHgiAAAAAFyI4qU1bZCSr6xL5yTMuPnXb42U"
                    onChange={value => {
                      if (value) {
                        this.handleInput({ target: { name: 'g-recaptcha-response', value } })
                        this.setState({ captcha: true })
                      }
                    }}
                  />
                </div>
                <div style={{ paddingTop: formSpacing }}>
                  <Button
                    type='submit'
                    setTheme='darkBlue'
                    disabled={!valid}
                  >
                    {status === 'SUCCESS' ? 'Thank You' : 'Submit'}
                  </Button>
                </div>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
    </FormSection>
  )
}

SmartsheetForm.propTypes = {
  title: PropTypes.string,
  sheetId: PropTypes.string,
  mediaWidth: 'normal',
  mediaPlacement: 'left'
}

export default SmartsheetForm
