import React, { Component } from 'react'
import styled from '@emotion/styled'
import ReCAPTCHA from 'react-google-recaptcha'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Input from 'src/components/Input'
import TextArea from 'src/components/TextArea'
import Select from 'src/components/Select'
import Button from 'src/components/Button'
import ScrollEntrance from 'src/components/ScrollEntrance'
import { mq, globals } from 'src/styles'
import { validateEmail, validatePhoneNumber } from 'src/utils/validations'

const FormSection = styled(Section)`
  ${mq.largeAndBelow} {
    ${ globals.verticalSpacing('padding-bottom') }
  }
`

class ContactForm extends Component {
  constructor (props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      subject: '',
      message: ''
    }
  }

  handleInput = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render () {
    const {
      className,
      theme,
      prevTheme,
      nextTheme,
      title,
      subjects,
      actions,
      isFirstSection,
    } = this.props
    const formSpacing = '14px'

    const { status, firstName, lastName, email, phone, subject, message, captcha } = this.state
    const valid = firstName !== '' && lastName !== '' && email !== '' && subject !== '' && message !== '' && validateEmail(email) && captcha === true

    const subjectsArray = []

    subjects.forEach((item) => {
      subjectsArray.push({ label: item, value: item })
    })

    const recaptchaRef = React.createRef()

    return (
      <FormSection
        className={className}
        prevTheme={prevTheme}
        nextTheme={nextTheme}
        setTheme={theme}
        isFirstSection={isFirstSection}
      >
        <Grid small='container' extraLarge='2 [10] 2'>
          <Grid small='[1]' large='[4] [8]' vAlign='top' as={ScrollEntrance} rowGap='24px'>
            <div>
              <h1 style={{ margin: 0 }}>{title || 'Contact Us'}</h1>
            </div>
            <div>
              <form
                onSubmit={this.submitForm}
                action="https://formspree.io/mdoypwdk"
                method="POST"
              >
                <Grid small='[1]' rowGap={formSpacing}>
                  <Grid vAlign='top' small='[1]' medium='[1] [1]' colGap={formSpacing} rowGap={formSpacing}>
                    <div>
                      <Input
                        onChange={this.handleInput}
                        name='firstName'
                        value={firstName}
                        label='First Name'
                      />
                    </div>
                    <div>
                      <Input
                        onChange={this.handleInput}
                        name='lastName'
                        value={lastName}
                        label='Last Name'
                      />
                    </div>
                  </Grid>
                  <div>
                    <Input
                      onChange={this.handleInput}
                      name='email'
                      value={email}
                      label='Email'
                      type='email'
                      isValid={email !== '' ? validateEmail(email) : true}
                    />
                  </div>
                  <div>
                    <Input
                      onChange={this.handleInput}
                      name='phone'
                      value={phone}
                      label='Phone'
                      type='tel'
                      isValid={phone !== '' ? validatePhoneNumber(phone) : true}
                    />
                  </div>
                  <div>
                    <Select
                      label="Subject"
                      name="subject"
                      onChange={this.handleInput}
                      value={subject}
                      options={subjectsArray}
                    />
                  </div>
                  <div>
                    <TextArea
                      onChange={this.handleInput}
                      name='message'
                      value={message}
                      label='Message'
                    />
                  </div>
                  <div>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LfuGHgiAAAAAFyI4qU1bZCSr6xL5yTMuPnXb42U"
                      onChange={value => {
                        if (value) {
                          this.handleInput({ target: { name: 'g-recaptcha-response', value } })
                          this.setState({ captcha: true })
                        }
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: formSpacing }}>
                    <Button
                      type='submit'
                      setTheme='darkBlue'
                      disabled={!valid}
                    >
                      {status === 'SUCCESS' ? 'Thank You' : 'Submit'}
                    </Button>
                  </div>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </FormSection>
    )
  }

  submitForm (ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        this.setState({ status: 'SUCCESS' })
        setTimeout(() => {
          this.setState({
            status: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            subject: '',
            message: ''
          })
          form.reset()
          window.location.href = "/thank-you"
        }, 1000)
      } else {
        this.setState({ status: 'ERROR' })
      }
    }
    xhr.send(data)
  }
}

ContactForm.defaultProps = {
  mediaWidth: 'normal',
  mediaPlacement: 'left'
}

export default ContactForm
