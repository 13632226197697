import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Select from "react-select"
import { Element } from "react-scroll"
import { groupBy } from 'lodash'

import Section from "src/components/Section"
import Grid from "src/components/Grid"
import Radio from "src/components/Radio"

import { headerHeightCollapsed } from "src/components/Header"

import { typography, colors, util, animations, mq } from "src/styles"

const Wrapper = styled(Section)``

const StyledSelect = styled(Select)`
	${typography.body};
	input {
		${typography.body};
	}
`

const IndicatorSeparator = ({ children, ...props }) => {
	return null
}

const selectStyles = () => ({
	control: () => ({
		// none of react-select's styles are passed to <Control />
		// width: 200,
		borderRadius: 0,
		alignItems: "center",
		cursor: "default",
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-between",
		maxHeight: "60px",
		maxWidth: "340px",
		outline: "0!important",
		transition: "all 100ms",
		boxSizing: "border-box",
		position: "relative",
		paddingLeft: "2px",
		border: `1px solid ${colors.hrColor}`,
	}),

	menu: (provided, state) => {
		return {
			...provided,
			borderRadius: 0,
			backgroundColor: colors.white,
			marginTop: -1,
			marginBottom: 0,
			boxShadow: "none",
			padding: 0,
			border: `1px solid ${colors.hrColor}`,
			borderTop: "none",
			width: "100%",
		}
	},

	option: (provided, state) => {
		return {
			...provided,
			":active": {
				backgroundColor: colors.mainColor,
			},

			backgroundColor: state.isFocused ? colors.grey3 : false,
		}
	},

	multiValue: (provided, state) => {
		return {
			...provided,
			backgroundColor: colors.mainColorLighten,
		}
	},

	multiValueLabel: (provided, state) => {
		return { ...provided, color: colors.white }
	},

	valueContainer: (provided, state) => {
		return { ...provided, paddingLeft: 0 }
	},

	multiValueRemove: (provided, state) => {
		return {
			...provided,
			cursor: "pointer",
			":hover": {
				backgroundColor: false,
				color: colors.white,
			},
			color: colors.mainColorDarken,
		}
	},

	input: (provided, state) => {
		return {
			...provided,
			fontFamily: "Freight Sans",
			color: colors.textColor,
			paddingLeft: 3,
		}
	},

	placeholder: (provided, state) => {
		return { ...provided, color: colors.lightTextColor, paddingLeft: 3 }
	},
	dropdownIndicator: (provided, state) => {
		return {
			...provided,
			color: state.isFocused ? colors.grey4 : colors.hrColor,
			":hover": {
				color: colors.mainColorLighten,
			},
		}
	},
})

const HiringThingContainer = styled.div`
	.ht-apply-link {
		display: block;
		margin-bottom: 30px;
		${typography.smallCaps}
		position: relative;
		padding-right: 24px;
		display: inline-block;
		line-height: 1.2em;
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 100%;
			left: 0;
			width: calc(100% - 24px);
			height: 1px;
			background: ${colors.mainColor};
			transform: scaleX(0);
			transform-origin: right center;
			transition: transform ${animations.mediumSpeed} ease-in-out;
		}
		&:hover {
			&:after {
				transform: rotate(-45deg);
			}
			&:before {
				transform-origin: left center;
				transform: scaleX(1);
			}
		}
		&:after {
			content: "arrow_forward";
			font-family: "Material Icons";
			font-weight: normal;
			font-style: normal;
			display: inline-block;
			line-height: 1em;
			text-transform: none;
			letter-spacing: normal;
			word-wrap: normal;
			white-space: nowrap;
			direction: ltr;
			${util.fontSmoothing}
			font-feature-settings: 'liga';
			color: ${colors.mainColor};
			font-size: 18px;
			position: absolute;
			right: 0;
			top: 0;
			margin-top: -0.1em;
			transition: transform ${animations.mediumSpeed} ease-in-out,
				color ${animations.mediumSpeed} ease-in-out;
		}
	}
	.ht-title-link {
		${typography.bodyExtraLarge}
		display: block;
		font-size: 1.4em;
		cursor: pointer;
		border-top: 1px solid ${colors.hrColor};
		margin: 0;
		padding-top: 20px;
	}
	.ht-summary {
		max-width: 800px;
		display: none;
	}
	.ht-location {
		font-size: 0.8em;
		margin: 0;
		color: ${colors.lightTextColor};
		${typography.bodySmall}
		font-weight: ${typography.medium};
	}
	.ht-no-positions {
		font-size: 1.4em;
	}
`

const HiringThingFilters = styled.div``

const HiringThing = ({
	className,
	theme,
	prevTheme,
	nextTheme,
	isFirstSection,
	fullWidth,
	title,
}) => {
	const [jobs, setJobs] = useState([])
	const [tags, setTags] = useState([])
	const [states, setStates] = useState([])
	const [hospitalStates, setHospitalStates] = useState([])
	const [hospitals, setHospitals] = useState([])
	const [typeFilter, setTypeFilter] = useState("any")
	const [remoteFilter, setRemoteFilter] = useState("any")

	const [tagFilter, setTagFilter] = useState([])
	const [stateFilter, setStateFilter] = useState([])
	const [hospitalFilter, setHospitalFilter] = useState([])

	useEffect(() => {
		if (typeof window !== "undefined") {
			const username = process.env.GATSBY_HIRING_THING_API_KEY
			const password = process.env.GATSBY_HIRING_THING_API_SECRET
			const url = "https://api.applicant-tracking.com/api/v1/jobs/"
			const request = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Basic ${btoa(username + ":" + password)}`,
				},
			}

			fetch(url, request)
				.then((response) => response.json())
				.then((json) => {
					const jobs = json.filter(({ status }) => status === "online")
					const tags = jobs.reduce((tags, job) => {
						job.tags.forEach((tag) => {
							if (tags.indexOf(tag) === -1) tags.push(tag)
						})
						return tags
					}, [])

					const states = jobs.reduce((states, job) => {
						if (states.indexOf(job.state) === -1) states.push(job.state)
						return states
					}, [])

					setJobs(jobs)
					setTags(tags)
					setStates(states)
					setHospitalStates(Object.keys(groupBy(json, 'hiring_entity_name')).map(hospital => ({ hospital, state: json.find(({ hiring_entity_name }) => hiring_entity_name === hospital).state })))
					setHospitals(Object.keys(groupBy(json, 'hiring_entity_name')).sort())
				})
		}
	}, [])

	return (
		<Element name="hiring-thing">
			<Wrapper
				className={className}
				setTheme={theme}
				prevTheme={prevTheme}
				nextTheme={nextTheme}
				isFirstSection={isFirstSection}
				padded={!fullWidth}
			>
				<Grid small="1 [12] 1" extraLarge="2 [10] 2">
					<Grid
						small="[1]"
						large="[4] [8]"
						larger="[4] 1 [7]"
						extraLarge="[4] 2 [6]"
						colGap="40px"
						rowGap="24px"
						vAlign="top"
					>
						<div
							css={css`
								height: 100%;
								${mq.largerAndUp} {
									position: sticky;
								}
							`}
						>
							<h3
								css={css`
									margin-top: 0;
									${mq.largerAndUp} {
										${headerHeightCollapsed("top", 40)}
										padding-bottom: 30px;
									}
								`}
							>
								{title}
							</h3>
							<HiringThingFilters>
								<Grid
									small="[1]"
									medium="[2] [4] [2] [4]"
									large="[1]"
									colGap="40px"
									rowGap="24px"
									vAlign="top"
								>
									<div>
										<h6>Type</h6>
										<Radio
											onChange={(event) => {
												setTypeFilter("any")
											}}
											id="type_any"
											name="type"
											value="any"
											checked={typeFilter === "any"}
										>
											Any
										</Radio>

										<Radio
											onChange={(event) => {
												setTypeFilter("Home Office")
											}}
											id="type_corporate"
											name="type"
											value="Home Office"
											checked={typeFilter === "Home Office"}
										>
											Home Office
										</Radio>

										<Radio
											onChange={(event) => {
												setTypeFilter("Veterinarians")
											}}
											id="type_vet"
											name="type"
											value="Veterinarians"
											checked={typeFilter === "Veterinarians"}
										>
											Veterinarians
										</Radio>

										<Radio
											onChange={(event) => {
												setTypeFilter("Hospital Teams")
											}}
											id="type_hospital"
											name="type"
											value="Hospital Teams"
											checked={typeFilter === "Hospital Teams"}
										>
											Hospital Teams
										</Radio>
									</div>
									<div>
										<h6>Location</h6>
										<StyledSelect
											classNamePrefix="location"
											isClearable={false}
											styles={selectStyles()}
											components={{ IndicatorSeparator }}
											options={states.map((s) => ({ label: s, value: s }))}
											isMulti
											value={stateFilter.map((s) => ({ label: s, value: s }))}
											onChange={(values, action) => {
												setStateFilter(values.map(({ value }) => value))
											}}
										/>
									</div>
									<div>
										<h6>Hospital</h6>
										<StyledSelect
											classNamePrefix="location"
											isClearable={false}
											styles={selectStyles()}
											components={{ IndicatorSeparator }}
											options={hospitals.filter(h => stateFilter.length === 0 || hospitalStates.find(({ hospital, state }) => h === hospital && stateFilter.includes(state))).map((s) => ({ label: s, value: s }))}
											isMulti
											value={hospitalFilter.map((s) => ({ label: s, value: s }))}
											onChange={(values, action) => {
												setHospitalFilter(values.map(({ value }) => value))
											}}
										/>
									</div>
									<div>
										<h6>Remote</h6>

										<Radio
											onChange={(event) => {
												setRemoteFilter(event.target.value)
											}}
											id="remote_any"
											name="remote"
											value="any"
											checked={remoteFilter === "any"}
										>
											Any
										</Radio>

										<Radio
											onChange={(event) => {
												setRemoteFilter(event.target.value)
											}}
											id="remote_yes"
											name="remote"
											value="yes"
											checked={remoteFilter === "yes"}
										>
											Yes
										</Radio>

										<Radio
											onChange={(event) => {
												setRemoteFilter(event.target.value)
											}}
											id="remote_no"
											name="remote"
											value="no"
											checked={remoteFilter === "no"}
										>
											No
										</Radio>
									</div>
									{/* <div>
										<h6>Tags</h6>
										<StyledSelect
											classNamePrefix="tags"
											isClearable={false}
											styles={selectStyles()}
											components={{ IndicatorSeparator }}
											options={tags.map((t) => ({ label: t, value: t }))}
											isMulti
											value={tagFilter.map((t) => ({ label: t, value: t }))}
											onChange={(values, action) => {
												setTagFilter(values.map(({ value }) => value))
											}}
										/>
									</div> */}
								</Grid>
							</HiringThingFilters>
						</div>
						<HiringThingContainer>
							{jobs
								?.filter((job) => {
									let pass = true
									if (remoteFilter && remoteFilter !== "any") {
										if (remoteFilter === "yes") {
											pass = pass && job.remote
										}
										if (remoteFilter === "no") {
											pass = pass && !job.remote
										}
									}

									if (typeFilter && typeFilter !== "any") {
										if (typeFilter === "Home Office") {
											pass = pass && job.job_code === "Home Office"
										}
										if (typeFilter === "Veterinarians") {
											pass = pass && job.job_code === "Veterinarians"
										}
										if (typeFilter === "Hospital Teams") {
											pass = pass && job.job_code === "Hospital Teams"
										}
									}

									if (stateFilter && stateFilter.length > 0) {
										pass = pass && stateFilter.includes(job.state)
									}

									if (hospitalFilter && hospitalFilter.length > 0) {
										pass = pass && hospitalFilter.includes(job.hiring_entity_name)
									}

									if (tagFilter && tagFilter.length > 0) {
										pass =
											pass && tagFilter.some((tag) => job.tags.includes(tag))
									}

									return pass
								})
								?.map((job) => (
									<div key={job.id}>
										<a href={job.joblink} target="_blank" class="ht-title-link">
											{job.title}
										</a>
										<div class="ht-location">{`${job.city}, ${job.state} ${
											job.country !== "US" ? job.country : ""
										}`}</div>
										<div class="ht-summary">{job.abstract}</div>
										<a href={job.joblink} target="_blank" class="ht-apply-link">
											Apply Now
										</a>
									</div>
								))}
						</HiringThingContainer>
					</Grid>
				</Grid>
			</Wrapper>
		</Element>
	)
}

export default HiringThing
