import React, { useState, useEffect } from "react"
//import { isMobile } from "react-device-detect"

import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { GatsbyImage } from "gatsby-plugin-image"
import Video from "src/components/Video"
import ScrollEntrance from "src/components/ScrollEntrance"
import Button from "src/components/Button"
import { mq, globals, typography, util, colors } from "src/styles"

const Wrapper = styled.div``

const MediaItem = styled.div`
  overflow: hidden;
  z-index: 1;
  ${util.responsiveStyles("border-radius", 50, 40, 30, 30)}
  position: relative;
  padding-bottom: ${(16 / 9) * 100}%;
  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  video {
    top: 0;
    width: 100%;
    height: 100%;
  }
`

const mediaSizes = {
  default: {
    normal: (100 / 28) * 11,
    large: (100 / 28) * 12,
    extraLarge: (100 / 28) * 13,
  },
}

const TestimonialMedia = ({
  media,
  className,
  alt,
  playVideo,
  onPlay,
  onPause,
  autoPlay,
  index,
}) => {
  const image = media?.image?.asset
  const video = media?.video?.asset
  const videoPoster = media?.videoPoster?.asset

  let mobile = false
  if (typeof window !== "undefined") {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      mobile = true
    }
  }

  const [playing, setPlaying] = useState(false)
  const [mute, setMute] = useState(mobile || autoPlay)

  useEffect(() => {
    if (playVideo) {
      setPlaying(true)
    } else {
      setPlaying(false)
    }
  }, [playVideo])

  const playPause = (playing) => {
    if (playing && onPlay) {
      onPlay()
      setMute(false)
    }
    if (!playing && onPause) {
      onPause()
      setMute(true)
    }
  }

  if (!image && !video) {
    return false
  }

  // set responsive image sizes
  let sizes = "100vw"
  let imageSize = mediaSizes.default["normal"]
  sizes = "(min-width: " + mq.mediumBreakpoint + "px) " + imageSize + "vw, 86vw"

  if (!media) {
    return false
  }

  return (
    <Wrapper className={className}>
      {media.mediaType === "video" && (
        <div style={{ position: "relative", zIndex: 1 }}>
          <MediaItem
            as={Video}
            src={video.url}
            loop={false}
            muted={mute}
            autoPlay={autoPlay}
            playing={playing}
            poster={videoPoster?.url}
            onEnded={() => playPause(false)}
          />
          <Button
            css={css`
              position: absolute;
              ${util.responsiveStyles("bottom", 30, 20, 18, 18)}
              ${util.responsiveStyles("left", 30, 20, 18, 18)}
              z-index: 4;
            `}
            shape="circle"
            icon={playing ? "pause" : "play_arrow"}
            size="small"
            setTheme="white"
            onClick={() => playPause(!playing)}
          />
          <Button
            css={css`
              position: absolute;
              ${util.responsiveStyles("bottom", 30, 20, 18, 18)}
              ${util.responsiveStyles("left", 90, 70, 64, 64)}
              z-index: 4;
            `}
            shape="circle"
            icon={mute ? "volume_off" : "volume_up"}
            size="small"
            setTheme="white"
            onClick={() => setMute(!mute)}
          />
        </div>
      )}
      {media.mediaType === "image" && (
        <ScrollEntrance>
          <MediaItem
            as={GatsbyImage}
            image={image.gatsbyImageData}
            alt={alt}
            sizes={sizes}
            format={["auto", "avif", "webp"]}
          />
        </ScrollEntrance>
      )}
    </Wrapper>
  )
}

TestimonialMedia.defaultProps = {
  mediaWidth: "normal",
  mediaPlacement: "left",
}

export default TestimonialMedia
