import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { GatsbyImage } from "gatsby-plugin-image"
import Modal from "src/components/Modal"
import Section from "src/components/Section"
import Link from "src/components/Link"
import Button from "src/components/Button"
import Grid from "src/components/Grid"
import ScrollEntrance from "src/components/ScrollEntrance"
import SanityRichText from "src/components/SanityRichText"
import { mq, globals, colors, typography, animations, util } from "src/styles"
import { FaLinkedinIn } from "react-icons/fa"
import { MdMailOutline } from "react-icons/md"

const Wrapper = styled(Section)``

const MemberName = styled.p`
  display: block;
  margin: 0;
  ${typography.bodySmall}
  font-weight: ${typography.medium};
  line-height: 1.5em;
  margin-top: 0.75em;
`

const MemberTitle = styled.p`
  display: block;
  margin: 0;
  ${typography.bodySmall}
  font-weight: ${typography.medium};
  color: ${colors.lightTextColor};
  line-height: 1.5em;
`

const MemberItem = styled.a`
  display: block;
  p {
    transition: color ${animations.mediumSpeed} ease-in-out;
  }
  &:hover {
    ${MemberTitle} {
      color: ${colors.mainColor};
    }
  }
`

const Headshot = styled(GatsbyImage)`
  img {
    ${({ hotspotX, hotspotY }) =>
      hotspotX && hotspotY
        ? `
      object-position: ${hotspotX * 100 + "%"} ${hotspotY * 100 + "%"};
    `
        : ``}
    ${({ crop, hotspotX }) =>
      crop
        ? `
      width: ${crop.left * 100 + crop.right * 100 + 100}%;
      height: ${crop.top * 100 + crop.bottom * 100 + 100}%;
      left: ${crop.left * -100}%;
      right: ${crop.right * -100}%;
      top: ${crop.top * -100}%;
      bottom: ${crop.bottom * -100}%;
    `
        : ``}
  }
  > div:first-of-type {
    padding-top: 100% !important;
  }
`

const TeamMemberDetails = styled.div`
  width: 90vw;
  ${util.responsiveStyles("max-width", 1000, 800, 700, 600)}
  ${util.responsiveStyles("padding", 40, 40, 30, 16)}
`

const ContactLink = styled(Link)`
  ${mq.largerAndUp} {
    ${typography.bodySmall}
    font-weight: ${typography.medium};
  }
  font-weight: ${typography.medium};
  color: ${colors.mainColor};
  display: inline-block;
  position: relative;
  line-height: 1.3em;
  vertical-align: top;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background: currentcolor;
    transform: scaleX(0);
    transform-origin: right center;
    transition: transform ${animations.mediumSpeed} ease-in-out;
  }
  &:hover {
    &:after {
      transform-origin: left center;
      transform: scaleX(1);
    }
  }
  > svg {
    vertical-align: middle;
    margin-top: -3px;
    margin-right: 0.4em;
  }
`

const MemberBio = styled(SanityRichText)`
  p {
    ${mq.largerAndUp} {
      ${typography.bodySmall}
    }
  }
`

const CloseButton = styled(Button)`
  position: absolute;
  ${util.responsiveStyles("top", 20, 20, 15, 14)}
  ${util.responsiveStyles("right", 20, 20, 15, 16)}
  margin-right: -12px;
  margin-top: -12px;
  ${mq.largeAndBelow} {
    // background: ${colors.transparent};
    // color: ${colors.white};
    top: 10px;
    right: 10px;
  }
`

const DetailTitle = styled.h3`
  margin: 0;
  ${typography.h2}
  ${mq.largerAndUp} {
    margin: -0.3em 0 0 0;
    ${typography.h3}
    padding-right: 20px;
  }
`

const TeamGrid = ({
  className,
  theme,
  prevTheme,
  nextTheme,
  width = "default",
  isFirstSection,
  title,
  teamMembers,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [currentMember, setCurrentMember] = useState(false)
  const setTeamMember = (open, teamMember) => {
    const { bio, email, linkedIn} = teamMember
    if (open && !bio && !email && !linkedIn) {
      return
    }
    setModalOpen(open)
    let delay = 0
    if (!open) {
      delay = 300
    }
    setTimeout(() => {
      setCurrentMember(teamMember)
    }, delay)
  }

  const renderMemberDetails = (currentMember) => {
    const headshot = currentMember?.headshotWithPet || currentMember?.headshot
    return (
      <TeamMemberDetails>
        <Grid
          small="[1]"
          medium="[1]"
          large="[9] 1 [14]"
          vAlign="top"
          rowGap={["5vw", "3vw", "3vw"]}
        >
          <div>
            <Headshot
              image={headshot?.asset?.gatsbyImageData}
              alt={
                currentMember?.name + currentMember.title &&
                ", " + currentMember.title
              }
              sizes={"600px"}
              format={["auto", "avif", "webp"]}
              hotspotX={headshot?.hotspot?.x}
              hotspotY={headshot?.hotspot?.y}
              crop={headshot?.crop}
            />
          </div>
          <div>
            <DetailTitle>{currentMember.name}</DetailTitle>
            <p
              style={{
                margin: 0,
                color: colors.lightTextColor,
                fontWeight: typography.medium,
              }}
            >
              {currentMember.title}
            </p>
            {/*<p><b>bio:</b> {currentMember.bio}</p>*/}
            <MemberBio text={currentMember.bio} />
            {currentMember.email && (
              <div>
                <ContactLink
                  external
                  target="_blank"
                  to={"mailto:" + currentMember.email}
                >
                  <MdMailOutline size="16px" /> {currentMember.email}
                </ContactLink>
              </div>
            )}
            {currentMember.linkedIn && (
              <div>
                <ContactLink
                  external
                  target="_blank"
                  to={currentMember.linkedIn}
                >
                  <FaLinkedinIn size="16px" /> Connect on LinkedIn
                </ContactLink>
              </div>
            )}
          </div>
        </Grid>
        <CloseButton
          icon="close"
          setTheme="transparent"
          size="small"
          shape="circle"
          onClick={() => setTeamMember(false, false)}
        />
      </TeamMemberDetails>
    )
  }

  return (
    <>
      <Wrapper
        nextTheme={nextTheme}
        prevTheme={prevTheme}
        setTheme={theme}
        isFirstSection={isFirstSection}
      >
        <Grid small="container" extraLarge="2 [10] 2">
          {title && (
            <ScrollEntrance>
              <h2
                css={css`
                  margin-top: 0;
                  ${globals.verticalSpacing("margin-bottom", 0.5)}
                `}
              >
                {title}
              </h2>
            </ScrollEntrance>
          )}
          <Grid
            small="[1] [1]"
            medium="[1] [1]"
            large="[1] [1] [1]"
            larger="[1] [1] [1] [1]"
            colGap={["4vw", "24px", "32px"]}
            rowGap={["7vw", "5vw", "3vw"]}
            vAlign="top"
          >
            {teamMembers.map((item, index) => {
              const sizes =
                "(min-width: " +
                mq.mediumBreakpoint +
                "px) " +
                86 / 3 +
                "vw, (min-width: " +
                mq.smallBreakpoint +
                "px) " +
                86 / 2 +
                "vw, " +
                86 / 2 +
                "vw"
              const member = item?.teamMemberContent
              const headshot = member?.headshot?.asset?.gatsbyImageData
              if (!headshot) {
                return false
              }
              return (
                <div key={member._key}>
                  <MemberItem
                    target="_blank"
                    to={member.website}
                    external
                    onClick={() => setTeamMember(true, member)}
                  >
                    <Headshot
                      image={headshot}
                      alt={member?.name + member.title && ", " + member.title}
                      sizes={sizes}
                      format={["auto", "avif", "webp"]}
                      hotspotX={member?.headshot?.hotspot?.x}
                      hotspotY={member?.headshot?.hotspot?.y}
                      crop={member?.headshot?.crop}
                    />
                    <div>
                      <MemberName>{member.name}</MemberName>
                      {member.title && (
                        <MemberTitle>{member.title}</MemberTitle>
                      )}
                    </div>
                  </MemberItem>
                </div>
              )
            })}
          </Grid>
        </Grid>
      </Wrapper>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setTeamMember(false, false)}
      >
        {renderMemberDetails(currentMember)}
      </Modal>
    </>
  )
}

export default TeamGrid
