import React, { useState } from "react"
import styled from "@emotion/styled"
import Slider from "react-slick"
import { InView } from "react-intersection-observer"

import Section from "src/components/Section"
import Grid from "src/components/Grid"
import TextLockup from "src/components/TextLockup"
import TestimonialLarge from "src/components/TestimonialLarge"
import TestimonialSmall from "src/components/TestimonialSmall"
import ScrollEntrance from "src/components/ScrollEntrance"
import ResponsiveComponent from "src/components/ResponsiveComponent"
import { animations, colors, mq } from "src/styles"

const IntroTextSection = styled(Section)`
  padding-bottom: 0 !important;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    ${({ alignment }) =>
      alignment === "center" &&
      `
      margin-left: auto;
      margin-right: auto;
    `}
  }
  h1,
  h2,
  h3 {
    max-width: 20em;
  }
  h4,
  h4 {
    max-width: 26em;
  }
  h6 {
    max-width: 16em;
  }
  p {
    max-width: 36em;
  }
`

const IntroText = styled(Grid)`
  ${({ alignment }) =>
    alignment !== "right" &&
    `
    text-align: ${alignment};
  `}
  h1, h2, h3, h4, h5, h6, p {
    ${({ alignment }) =>
      alignment === "center" &&
      `
      margin-left: auto;
      margin-right: auto;
    `}
  }
  h1,
  h2,
  h3 {
    max-width: 20em;
  }
  h4,
  h4 {
    max-width: 26em;
  }
  h6 {
    max-width: 16em;
  }
  p {
    max-width: 36em;
  }
`

const QuoteSlider = styled(Slider)`
  position: relative;
  padding-bottom: 50px;
`

const Slide = styled.div`
  padding: 0 20px;
  width: 50vw !important;
  ${mq.mediumAndBelow} {
    padding: 0 15px;
    width: 70vw !important;
  }
`

const SlideshowDots = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
`

const SlideshowDot = styled.li`
  width: 10px;
  height: 10px;
  background: ${colors.grey4};
  cursor: pointer;
  border-radius: 50%;
  margin: 0 5px;
  transform: scale(0.6);
  transition: transform ${animations.mediumSpeed} ease-in-out,
    background ${animations.mediumSpeed} ease-in-out;
  .slick-active & {
    transform: none;
    background: ${colors.mainColor};
  }
`

const TestimonialList = ({
  className,
  theme,
  prevTheme,
  nextTheme,
  media,
  mediaPlacement = "left",
  mediaWidth = "normal",
  width = "default",
  eyebrow,
  layout,
  introText,
  isFirstSection,
  testimonial,
}) => {
  const [videosPlaying, setVideosPlaying] = useState(
    testimonial.map((t) => false)
  )

  const onPlay = (index) => {
    // pause all other videos
    const update = [...videosPlaying].map((t, i) =>
      i === index ? true : false
    )
    setVideosPlaying(update)
  }

  const onPause = (index) => {
    const update = [...videosPlaying]
    update[index] = false
    setVideosPlaying(update)
  }

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: false,
    centerMode: true,
    swipe: true,
    accesibility: true,
    speed: 500, // swipe animation speed
    variableWidth: true,
    adaptiveHeight: true,
    afterChange: (index, test) => {
      onPlay(index)
    },
    appendDots: (dots) => <SlideshowDots>{dots}</SlideshowDots>,
    customPaging: (slider, i) => (
      <SlideshowDot active={slider === i}>
        {i}
        {slider.current}
      </SlideshowDot>
    ),
  }

  return (
    <>
      {introText && (
        <IntroTextSection setTheme={theme} prevTheme={prevTheme}>
          <IntroText
            small="container"
            medium="container"
            large="container"
            alignment="center"
          >
            <div>
              <TextLockup text={introText} alignment="center" />
            </div>
          </IntroText>
        </IntroTextSection>
      )}
      <ResponsiveComponent
        small={
          <Section setTheme={theme} prevTheme={prevTheme} nextTheme={nextTheme}>
            <QuoteSlider {...sliderSettings}>
              {testimonial.map((item, index) => {
                return (
                  <Slide>
                    <TestimonialSmall
                      item={item}
                      index={index}
                      theme={theme}
                      prevTheme={prevTheme}
                      nextTheme={nextTheme}
                      onPlay={() => {
                        onPlay(index)
                      }}
                      onPause={() => {
                        onPause(index)
                      }}
                      playVideo={videosPlaying[index]}
                      isLastSection={index === testimonial.length - 1}
                      isFirstSection={isFirstSection}
                      testimonialsLength={testimonial.length}
                    />
                  </Slide>
                )
              })}
            </QuoteSlider>
          </Section>
        }
        large={
          layout !== "columns" ? (
            <div>
              {testimonial.map((item, index) => {
                return (
                  <InView
                    threshold={0.8}
                    onChange={(inView, entry) => {
                      if (inView) {
                        onPlay(index)
                      } else {
                        onPause(index)
                      }
                    }}
                  >
                    <TestimonialLarge
                      item={item}
                      index={index}
                      theme={theme}
                      prevTheme={prevTheme}
                      nextTheme={nextTheme}
                      onPlay={() => {
                        onPlay(index)
                      }}
                      onPause={() => {
                        onPause(index)
                      }}
                      autoPlay={true}
                      playVideo={videosPlaying[index]}
                      isLastSection={index === testimonial.length - 1}
                      isFirstSection={isFirstSection}
                      testimonialsLength={testimonial.length}
                    />
                  </InView>
                )
              })}
            </div>
          ) : (
            <Section
              setTheme={theme}
              prevTheme={prevTheme}
              nextTheme={nextTheme}
            >
              <Grid small="container" extraLarge="2 [10] 2">
                <Grid
                  small="[1] [1] [1]"
                  colGap="5vw"
                  as={ScrollEntrance}
                  vAlign="top"
                >
                  {testimonial.map((item, index) => {
                    return (
                      <TestimonialSmall
                        item={item}
                        index={index}
                        theme={theme}
                        prevTheme={prevTheme}
                        nextTheme={nextTheme}
                        onPlay={() => {
                          onPlay(index)
                        }}
                        onPause={() => {
                          onPause(index)
                        }}
                        playVideo={videosPlaying[index]}
                        isLastSection={index === testimonial.length - 1}
                        isFirstSection={isFirstSection}
                        testimonialsLength={testimonial.length}
                      />
                    )
                  })}
                </Grid>
              </Grid>
            </Section>
          )
        }
      />
    </>
  )
}

TestimonialList.defaultProps = {
  mediaWidth: "normal",
  mediaPlacement: "left",
}

export default TestimonialList
