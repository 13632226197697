import React, { useContext } from "react"
import { graphql } from "gatsby"
// import { RenderModules } from 'src/utils/renderModules'
import SEO from "src/components/SEO"
import Header from "src/components/Header"
import Footer from "src/components/Footer"
import ComponentRenderer from "src/components/ComponentRenderer"
import Modal from "src/components/Modal"
import PartnerForm from "src/components/PartnerForm"
import { AppContext } from "src/state/AppState"

const Page = ({ data }) => {
  const page = data?.sanityPage?.content?.main
  const menus = data?.allSanityMenus?.edges
  const pageMeta = data?.sanityPage?.content?.meta
  const path = page?.slug?.current
  let modules = page?.modules
  // Filter out hidden modules
  modules = modules.filter((module) => !module.hidden)

  let hasAtf =
    (modules[0]?._type === "wideMedia" && modules[0]?.width === "fullWidth") ||
    modules[0]?.theme === "darkBlue"

  const siteSettings = data?.allSanitySiteSettings?.edges[0]?.node
  const siteTitle = siteSettings?.title
  const newsletterText = siteSettings?.newsletterText
  const socialLinks = siteSettings?.socialLinks?.socialLinks

  const leftNavigation = menus.filter(
    (menu) => menu?.node?.slug?.current === "left-navigation"
  )[0]?.node?.items
  const rightNavigation = menus.filter(
    (menu) => menu?.node?.slug?.current === "right-navigation"
  )[0]?.node?.items
  const footerColumn1 = menus.filter(
    (menu) => menu?.node?.slug?.current === "footer-column-1"
  )[0]?.node
  const footerColumn2 = menus.filter(
    (menu) => menu?.node?.slug?.current === "footer-column-2"
  )[0]?.node

  const { modal, toggleModal } = useContext(AppContext)

  return (
    <>
      <SEO
        pagePath={path}
        title={pageMeta?.metaTitle || page.title}
        description={pageMeta?.metaDescription}
        keywords={pageMeta?.metaKeywords}
        ogTitle={pageMeta?.openTitle}
        ogImage={pageMeta?.openImage?.asset?.url}
        ogDescription={pageMeta?.openGraphDescription}
        twitterDescription={pageMeta?.twitterDescription}
        twitterImage={pageMeta?.twitterImage?.asset?.url}
        twitterTitle={pageMeta?.twitterTitle}
      />
      <Header
        hasAtf={hasAtf}
        // bannerText={site.bannerText}
        // bannerColor={site.bannerColor}
        leftNavigation={leftNavigation}
        rightNavigation={rightNavigation}
        location={path}
        title={siteTitle}
      />
      {modules.map((item, index) => {
        const prevSection = modules[index - 1]
        const nextSection = modules[index + 1]
        let prevTheme = false
        let nextTheme = false
        if (prevSection && prevSection.width !== "fullWidth") {
          prevTheme = prevSection.theme || "default"
        }
        if (nextSection && nextSection.width !== "fullWidth") {
          nextTheme = nextSection.theme || "default"
        }
        if (!item.theme && item?.width !== "fullWidth") {
          item.theme = "default"
        }

        return (
          <ComponentRenderer
            item={item}
            key={item?._key || "section-" + index}
            nextTheme={nextTheme}
            prevTheme={prevTheme}
            isFirstSection={index === 0}
            isLastSection={index === modules.length - 1}
          />
        )
      })}
      <Footer
        title={siteTitle}
        footerColumn1={footerColumn1}
        footerColumn2={footerColumn2}
        newsletterText={newsletterText}
        socialLinks={socialLinks}
      />
      <Modal
        isOpen={modal === "partnerForm"}
        onRequestClose={() => toggleModal(false)}
      >
        <PartnerForm />
      </Modal>
    </>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    allSanitySiteSettings {
      edges {
        node {
          title
          newsletterText
          socialLinks {
            socialLinks {
              icon
              url
            }
          }
        }
      }
    }
    allSanityMenus {
      edges {
        node {
          _id
          _key
          title
          slug {
            current
          }
          items {
            ... on SanityLink {
              _key
              _type
              title
              hidden
              link {
                content {
                  main {
                    slug {
                      current
                    }
                  }
                }
                _key
                _id
              }
              externalLink
            }
            ... on SanityNavMenu {
              _key
              _type
              title
              hidden
              items {
                _key
                _type
                title
                externalLink
                link {
                  _key
                  _id
                  content {
                    main {
                      slug {
                        current
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    sanityPage(id: { eq: $id }) {
      content {
        main {
          title
          slug {
            current
          }
          modules {
            ...TextSection
            ...WideMedia
            ...FiftyFifty
            ...Columns
            ...LocationsList
            ...TestimonialsList
            ...TwoColText
            ...ContactForm
            ...SmartsheetForm
            ...MailchimpForm
            ...TeamGrid
            ...JobsListing
          }
        }
        meta {
          metaTitle
          metaDescription
          metaKeywords
          openGraphDescription
          openImage {
            asset {
              url
            }
          }
          openTitle
          twitterDescription
          twitterImage {
            asset {
              url
            }
          }
          twitterTitle
        }
      }
    }
  }
`

export default Page
