import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Button from "src/components/Button"
import TextLink from "src/components/TextLink"
import ScrollEntrance from "src/components/ScrollEntrance"
import SanityRichText from "src/components/SanityRichText"
import { typography, mq } from "src/styles"
import { themes } from "src/styles/themes"
import { getSlugLink } from "src/utils/format"
import { AppContext } from "src/state/AppState"

import * as Scroll from "react-scroll"
var scroller = Scroll.scroller
var scroll = Scroll.animateScroll

const Wrapper = styled.div`
	display: inline-block;
	display: block;
	vertical-align: top;
	text-align: ${({ alignment }) => alignment};
	${({ alignment }) =>
		alignment === "center" &&
		`
		margin-left: auto;
		margin-right: auto;
		> div {
			margin-left: auto;
			margin-right: auto;
		}
	`}
	${mq.mediumAndBelow} {
		display: block;
	}
`
const TextContainer = styled(ScrollEntrance)`
	width: 100%;
	${({ alignment }) =>
		alignment === "center" &&
		`
		margin-left: auto;
		margin-right: auto;
		h1, h2, h3, h4, h5, h6, p, blockquote, ul {
			margin-left: auto;
			margin-right: auto;
		}
	`}
	${({ alignment }) =>
		alignment === "right" &&
		`
		margin-left: auto;
		h1, h2, h3, h4, h5, h6, p, blockquote, ul {
			margin-left: auto;
		}
	`}
`

const Eyebrow = styled.p`
	${typography.eyebrow}
	${({ hasText }) =>
		hasText
			? `
		margin: 0 0 1em;
	`
			: `
		margin: 0;
	`}
`

const Headline = styled.h3`
	${({ headlineSize }) => typography[headlineSize]}
	${({ hasText }) =>
		!hasText &&
		`
		margin-bottom: 0;
	`}
	${({ hasEyebrow }) =>
		!hasEyebrow &&
		`
		margin-top: 0;
	`}
`

const Text = styled.div`
	// p {
	// 	${({ textSize }) => typography[textSize]}
	// }
`

const ActionWrapper = styled.div`
	padding: 16px 10px 0;
	display: inline-block;
	vertical-align: middle;
`

const ButtonActions = styled.div`
	margin-top: 8px;
	text-align: ${({ alignment }) => alignment};
	margin-left: -10px;
	margin-right: -10px;
`

const TextLockup = ({
	theme = "default",
	eyebrow,
	headline,
	headlineSize,
	headlineElement,
	text,
	textSize,
	actions,
	className,
	icon,
	alignment,
	additions,
	entranceDelay,
	transitionIn,
	listType,
}) => {
	const { toggleModal } = useContext(AppContext)
	eyebrow = eyebrow || text?.eyebrow
	text = text?._rawText || text?.text || text

	return (
		<Wrapper className={className} alignment={alignment}>
			<div>
				<TextContainer
					alignment={alignment}
					delay={entranceDelay}
					transitionIn={transitionIn}
				>
					{eyebrow && (
						<div>
							<Eyebrow
								className="eyebrow"
								hasText={headline || text}
								alignment={alignment}
							>
								{eyebrow}
							</Eyebrow>
						</div>
					)}

					{headline && (
						<Headline
							as={headlineElement || headlineSize}
							headlineSize={headlineSize}
							hasText={text}
							hasEyebrow={eyebrow}
						>
							{headline}
						</Headline>
					)}

					{text && Array.isArray(text) && (
						<Text textSize={textSize} alignment={alignment}>
							<SanityRichText text={text} listType={listType} />
						</Text>
					)}

					{typeof text === "string" && (
						<Text textSize={textSize} alignment={alignment}>
							<p>{text}</p>
						</Text>
					)}

					{text &&
						typeof text !== "string" &&
						!Array.isArray(text) &&
						typeof text !== "object" && (
							<Text textSize={textSize} alignment={alignment}>
								{text}
							</Text>
						)}

					{actions && actions.length > 0 && (
						<ButtonActions
							buttons={actions}
							alignment={alignment}
							className="actions"
						>
							{actions.map((action, index) => {
								// Scroll Buttons

								if (action._type === "button") {
									let actionTheme = "default"
									if (action.theme === "primary") {
										actionTheme = themes[theme].buttonTheme || "default"
									} else if (action.theme === "secondary") {
										actionTheme =
											themes[theme].buttonThemeSecondary || "default"
									}
									return (
										<ActionWrapper key={"button-" + index}>
											<Button
												setTheme={actionTheme}
												to={
													action.action === "none" || !action.action
														? action.externalLink || getSlugLink(action.link)
														: false
												}
												onClick={() => {
													if (action.action === "partnerForm") {
														toggleModal("partnerForm")
													}
													if (action.action === "scrollTo") {
														scroller.scrollTo(action.scrollSection, {
															duration: 1500,
															smooth: true,
														})
													}
													if (typeof action.action === 'function') {
														action.action()
													}
												}}
												external={action.externalLink}
												target={action.newTab ? "_blank" : ""}
												title={action.title}
												name={action.title}
												icon={action.icon}
											>
												{action.title}
											</Button>
										</ActionWrapper>
									)
								} else {
									return (
										<ActionWrapper key={"button-" + index}>
											<TextLink
												to={action.externalLink || getSlugLink(action.link)}
												external={action.externalLink}
												target={action.newTab ? "_blank" : ""}
												title={action.title}
												name={action.title}
											>
												{action.title}
											</TextLink>
										</ActionWrapper>
									)
								}
							})}
						</ButtonActions>
					)}
				</TextContainer>
			</div>
		</Wrapper>
	)
}

TextLockup.defaultProps = {
	alignment: "inherit",
	textSize: "body",
	entranceDelay: 0,
	transitionIn: true,
	headlineSize: "h3",
}

export default TextLockup
