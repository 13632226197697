import React from 'react'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import { GatsbyImage } from 'gatsby-plugin-image'
import Video from 'src/components/Video'
import TextLockup from 'src/components/TextLockup'
import ScrollEntrance from 'src/components/ScrollEntrance'
import MediaCaption from 'src/components/MediaCaption'
import { mq, globals, colors, typography } from 'src/styles'
import * as themes from 'src/styles/themes'
import { toPlainText } from 'src/utils/format'
import ThemeSelector from 'src/components/ThemeSelector'

const FFSection = styled(Section)`
  ${ ({ isFirstSection }) => isFirstSection ? `
    position: relative;
    padding-bottom: 0 !important;
  ` : `
    ${mq.largeAndBelow} {
      ${ globals.verticalSpacing('padding-bottom') }
    }
  ` }
`

const FFMediaCaption = styled(MediaCaption)`
  position: absolute;
  bottom: 3vw;
  left: 3vw;
  max-width: calc(100% - 6vw);
  ${ mq.extraLargeAndUp } {
    bottom: 2vw;
    left: 2vw;
    max-width: calc(100% - 4vw);
  }
  ${ ({ isFirstSection }) => isFirstSection ? `
    padding: 0;
    border-radius: 0;
    position: static;
    background: transparent;
    padding-top: .5em;
  ` : `` }
  ${ mq.mediumAndBelow } {
    bottom: 12px;
    left: 12px;
    max-width: calc(100% - 24px);
    // background: ${ rgba(colors.textColor, .8) };
    // color: ${ colors.white };
  }
`

const ColumnWrapper = styled.div`
  z-index: 2;
  ${({ fullWidth }) => fullWidth && `
    height: 100%;
    // get to work for video also
    > div,
    .gatsby-image-wrapper,
    .video-wrapper,
    .video-wrapper > div,
    .video-wrapper > div > div {
      height: 100%;
    }
    .video-wrapper > div > div {
      padding-bottom: ${9 / 16 * 100}%;
    }
    .video-wrapper video {
      position: absolute;
      object-fit: cover;
    }
  `}
  h1, h2, h3 {
    max-width: 20em;
  }
  h4, h4 {
    max-width: 26em;
  }
  h6 {
    max-width: 16em;
  }
  p {
    max-width: 32em;
  }
`

const TextWrapper = styled.div`
  ${ ({ isFirstSection, nextTheme }) => isFirstSection ? `
    padding-bottom: 10vw;
    ${ mq.largeAndBelow } {
      padding-bottom: 0;
      color: ${ themes[nextTheme]?.color || colors.textColor };
      > div {
        color: ${ themes[nextTheme]?.color || colors.textColor };
      }
    }
  ` : `` }
  ${({ fullWidth }) => fullWidth && `
    ${mq.largerAndUp} {
      ${ globals.verticalSpacing('padding-top') }
      ${ globals.verticalSpacing('padding-bottom') }
    }
  `}
  ${ ({ eyebrowStyle }) => eyebrowStyle === 'circle' ? `
    .eyebrow {
      background: ${ colors.grey2 };
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-bottom: .4em;
      ${ typography.h3 }
      color: ${ colors.lightTextColor };
      height: 2.6em;
      width: 2.6em;
    }
  ` : `` }
`

const NextThemePanel = styled(ThemeSelector)`
  width: 100%;
  height: 10vw;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  ${ mq.largeAndBelow } {
    height: 50%;
  }
`

const arrangeMedia = {
  default: {
    small: '1 [9] 1 [13]',
    normal: '[11] 2 [11]',
    large: '[12] 2 [10]',
    extraLarge: '[13] 2 [9]'
  },
  fullWidth: {
    small: '1 [9] 1 [15] 2',
    normal: '[13] 2 [11] 2',
    large: '[14] 2 [10] 2',
    extraLarge: '[15] 2 [9] 2'
  }
}

const mediaSizes = {
  default: {
    normal: 100 / 28 * 11,
    large: 100 / 28 * 12,
    extraLarge: 100 / 28 * 13
  },
  fullWidth: {
    normal: 100 / 28 * 13,
    large: 100 / 28 * 14,
    extraLarge: 100 / 28 * 15
  }
}

const FiftyFifty = ({
  className,
  theme,
  prevTheme,
  nextTheme,
  media,
  mediaPlacement = 'left',
  mediaWidth = 'normal',
  width = 'default',
  eyebrow,
  text,
  actions,
  verticalAlignment = 'center',
  isFirstSection,
  listType,
  textAlignment = 'left',
  eyebrowStyle = 'circle'
}) => {
  const image = media?.image?.asset
  const video = media?.video?.asset

  if (!image && !video) {
    return false
  }

  let caption = false
  let plainTextCaption = false

  if (media?._rawCaption) {
    caption = media._rawCaption
    plainTextCaption = toPlainText(caption)
  }

  // set responsive image sizes
  let sizes = '100vw'
  let imageSize = mediaSizes.default[mediaWidth]
  sizes = '(min-width: ' + mq.mediumBreakpoint + 'px) ' + imageSize + 'vw, 86vw'

  const fullWidth = width === 'fullWidth'
  return (
    <FFSection
      className={className}
      setTheme={theme}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      isFirstSection={isFirstSection}
      padded={!fullWidth}
    >
      <Grid small={fullWidth ? '[1]' : '1 [12] 1'} extraLarge={fullWidth ? '[1]' : '2 [10] 2'}>
        <Grid
          small='[1]'
          large={arrangeMedia[width || 'default'][mediaWidth || 'normal']}
          rowGap={['6vw', '4vw', '80px']}
          vAlign={verticalAlignment}
          gridDirection={mediaPlacement?.includes('right') ? 'rtl' : 'ltr'}
        > 
          {media && (
            <ColumnWrapper fullWidth={fullWidth}>
              {media.mediaType === 'video' && (
                <ScrollEntrance>
                  <div style={{ position: 'relative', height: '100%' }}>
                    <Video src={video.url} />
                    {caption && (<FFMediaCaption text={caption} isFirstSection={isFirstSection} setTheme={isFirstSection ? nextTheme : 'default'} />)}
                  </div>
                </ScrollEntrance>
              )}
              {media.mediaType === 'image' && (
                <ScrollEntrance>
                  <div style={{ position: 'relative', height: '100%' }}>
                    <GatsbyImage
                      image={image.gatsbyImageData}
                      loading={isFirstSection ? 'eager' : 'lazy'}
                      alt={plainTextCaption || text?.eyebrow || media.originalFilename}
                      sizes={sizes}
                      format={['auto', 'avif', 'webp']}
                    />
                    {caption && (<FFMediaCaption text={caption} isFirstSection={isFirstSection} setTheme={isFirstSection ? nextTheme : 'default'}/>)}
                  </div>
                </ScrollEntrance>
              )}
            </ColumnWrapper>
          )}

          <ColumnWrapper fullWidth={fullWidth}>
            <Grid
              small={fullWidth ? '1 [12] 1' : '[1]'}
              large='[1]'
            >
              <TextWrapper fullWidth={fullWidth} isFirstSection={isFirstSection} nextTheme={nextTheme} eyebrowStyle={eyebrowStyle}>
                <TextLockup
                  entranceDelay={1}
                  text={text}
                  actions={actions}
                  theme={theme}
                  listType={listType}
                  alignment={textAlignment}
                />
              </TextWrapper>
            </Grid>
          </ColumnWrapper>
        </Grid>
      </Grid>
      {isFirstSection && (<NextThemePanel setTheme={nextTheme} />)}
    </FFSection>
  )
}

FiftyFifty.defaultProps = {
  mediaWidth: 'normal',
  mediaPlacement: 'left'
}

FiftyFifty.propTypes = {
  /** One of the themes specified in `src/styles/themes.js` */
  theme: PropTypes.string,
  /** Can be an image or video from Contentful */
  media: PropTypes.string,
  /** Where should the media be placed? */
  mediaPlacement: PropTypes.oneOf(['left', 'right']),
  /** How wide should the media be? */
  mediaWidth: PropTypes.oneOf(['normal', 'large', 'extraLarge']),
  width: PropTypes.oneOf(['default', 'fullWidth']),
  /** Text above the headline */
  eyebrow: PropTypes.string,
  /** `raw` rich text from Contentful */
  text: PropTypes.shape({ raw: PropTypes.string }),
  /** Buttons or links to go under text */
  actions: PropTypes.shape([
    {
      __typename: PropTypes.oneOf(['ContentfulButton', 'ContentfulLink']),
      to: PropTypes.string,
      linkToPage: PropTypes.shape({ slug: PropTypes.string }),
      openInNewTab: PropTypes.bool,
      label: PropTypes.string
    }
  ]),
  verticalAlignment: PropTypes.oneOf(['bottom', 'top', 'center', 'baseline', 'stretch']),
  /** Should we adjust the space to accomidate the header? */
  isFirstSection: PropTypes.bool,
}

export default FiftyFifty
