import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import TextLockup from 'src/components/TextLockup'
import Grid from 'src/components/Grid'

const Wrapper = styled(Section)`
  h1, h2, h3 {
    max-width: 20em;
  }
  h4, h4 {
    max-width: 26em;
  }
  h6 {
    max-width: 16em;
  }
  p {
    max-width: 36em;
  }
`

const TwoColText = ({
  className,
  nextTheme,
  prevTheme,
  theme,
  text,
  headline,
  actions,
  isFirstSection,
  id
}) => {
  if (!text && !text?.eyebrow && !actions) {
    return false
  }

  return (
    <Wrapper
      className={className}
      prevTheme={prevTheme}
      setTheme={theme}
      nextTheme={nextTheme}
      isFirstSection={isFirstSection}
    >
      <Grid small='container' extraLarge='2 [10] 2'>
        <Grid
          small="[1]"
          large="[5] 1 [6]"
          vAlign='top'
        >
          <div>
            <h2 style={{ marginTop: 0 }}>{headline}</h2>
          </div>
          <TextLockup
            text={text}
            // textSize={paragraphSize}
            actions={actions}
            theme={theme}
          />
        </Grid>
      </Grid>
    </Wrapper>
  )
}

TwoColText.propTypes = {
  /** One of the themes specified in `src/styles/themes.js` */
  theme: PropTypes.string,
  paragraphSize: PropTypes.oneOf(['body', 'bodyMedium', 'bodyLarge', 'bodySmall']),
  /** Text above the headline */
  eyebrow: PropTypes.string,
  /** `raw` rich text from Contentful */
  text: PropTypes.shape({ raw: PropTypes.string }),
  /** Buttons or links to go under text */
  actions: PropTypes.shape([
    {
      __typename: PropTypes.oneOf(['ContentfulButton', 'ContentfulLink']),
      to: PropTypes.string,
      linkToPage: PropTypes.shape({ slug: PropTypes.string }),
      openInNewTab: PropTypes.bool,
      label: PropTypes.string
    }
  ]),
  /** Should we adjust the space to accomidate the header? */
  isFirstSection: PropTypes.bool,
}

export default TwoColText
