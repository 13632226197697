import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Section from "src/components/Section"
import Grid from "src/components/Grid"
import Link from "src/components/Link"
import Map from "src/components/Map"
import ScrollEntrance from "src/components/ScrollEntrance"
import MaterialIcon from "src/components/MaterialIcon"
import { headerHeightCollapsed } from "src/components/Header"
import { globals, colors, typography, animations, mq } from "src/styles"

const Wrapper = styled(Section)``

const MapWrapper = styled.div`
  ${mq.largerAndUp} {
    position: sticky;
    ${headerHeightCollapsed("top", 40)}
  }
  #finder-google-map {
    position: absolute;
  }
`

const ArrowIcon = styled(MaterialIcon)`
  font-size: 24px;
  position: absolute;
  top: 2px;
  right: -4px;
  color: ${colors.grey4};
  opacity: 0;
  transition: opacity ${animations.mediumSpeed} ease-in-out,
    transform ${animations.mediumSpeed} ease-in-out;
  ${mq.mediumAndBelow} {
    opacity: 1;
    transform: rotate(-45deg);
  }
`

const LocationItem = styled(Link)`
  display: block;
  ${typography.bodyExtraLarge}
  min-height: 6em;
  padding: 0.6em 15px 0.65em;
  margin: 0 -15px;
  position: relative;
  p {
    transition: color ${animations.mediumSpeed} ease-in-out;
    padding-right: 30px;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    height: 1px;
    z-index: 1;
    background: ${colors.hrColor};
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(100% + 1px);
    z-index: 2;
    background: ${colors.grey2};
    opacity: 0;
    transition: opacity ${animations.mediumSpeed} ease-in-out;
  }
  &:hover {
    ${mq.largeAndUp} {
      ${ArrowIcon} {
        opacity: 1;
        transform: rotate(-45deg);
      }
      &:after {
        opacity: 1;
      }
      p {
        color: ${colors.mainColor};
      }
    }
  }
`

const LocationTitle = styled.h6`
  display: block;
  margin: 0;
  ${typography.bodyExtraLarge}
  line-height: 1.5em;
  padding-right: 30px;
`

const Location = ({ data: location }) => (
  <LocationItem
    key={location._key}
    target="_blank"
    to={location.website}
    external
  >
    <div style={{ position: "relative", zIndex: 3 }}>
      <LocationTitle>{location.title}</LocationTitle>
      {(location.city || location.state) && (
        <p
          css={css`
            margin: 0;
            color: ${colors.lightTextColor};
            ${typography.bodySmall}
            font-weight: ${typography.medium};
          `}
        >
          {location.city}{location.state && (', ' + location.state)}
        </p>
      )}
      <ArrowIcon>arrow_forward</ArrowIcon>
    </div>
  </LocationItem>
)

const LocationsList = ({
  className,
  theme,
  prevTheme,
  nextTheme,
  media,
  mediaPlacement = "left",
  mediaWidth = "normal",
  width = "default",
  eyebrow,
  text,
  actions,
  verticalAlignment = "center",
  isFirstSection,
  listType,
  data,
  title,
}) => {
  const { allSanityLocation } = useStaticQuery(
    graphql`
      query {
        allSanityLocation {
          edges {
            node {
              id
              location {
                _key
                facebook
                fax
                instagram
                location {
                  lng
                  lat
                  alt
                }
                phone
                title
                city
                state
                twitter
                website
                yelp
              }
            }
          }
        }
      }
    `
  )
  const locations = allSanityLocation?.edges
  const locationsArray = []

  locations.forEach((item, index) => {
    const location = item?.node?.location
    locationsArray.push({
      _key: location.item?.node?.id,
      lat: location.location.lat,
      lng: location.location.lng,
      location,
      infoWindow: (
        <div style={{ margin: '0px 15px 1px 5px', paddingLeft: '10px' }}>
          <Location data={item?.node?.location} />
        </div>
      ),
    })
  })
  return (
    <Wrapper
      nextTheme={nextTheme}
      prevTheme={prevTheme}
      setTheme={theme}
      isFirstSection={isFirstSection}
    >
      <Grid small="container" extraLarge="2 [10] 2">
        {title && (
          <ScrollEntrance>
            <h2
              css={css`
                margin-top: 0;
                ${globals.verticalSpacing("margin-bottom", 0.5)}
              `}
            >
              {title}
            </h2>
          </ScrollEntrance>
        )}
        <Grid
          small="[1]"
          large="[11] 1 [12]"
          vAlign="top"
          as={ScrollEntrance}
          delay={1}
          rowGap="4vw"
        >
          <div style={{ height: "100%" }}>
            <MapWrapper
              style={{ paddingBottom: "100%", background: colors.grey3 }}
            >
              <Map pins={locationsArray} />
            </MapWrapper>
          </div>
          <div>
            <Grid
              small="[1]"
              medium="[1] [1]"
              large="[1]"
              larger="[1] [1]"
              colGap="30px"
              vAlign="top"
            >
              {locations
                .sort((a, b) => {
                  if (a?.node?.location?.state < b?.node?.location?.state) {
                    return -1
                  }
                  if (a?.node?.location?.state > b?.node?.location?.state) {
                    return 1
                  }
                  return 0
                })
                .map((item, index) => {
                  const location = item?.node?.location
                  return (
                    <div>
                      <Location data={item?.node?.location} />
                    </div>
                  )
                })}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

LocationsList.defaultProps = {
  mediaWidth: "normal",
  mediaPlacement: "left",
}

export default LocationsList
