import React from "react"
import styled from "@emotion/styled"
import Section from "src/components/Section"
import Grid from "src/components/Grid"
import { GatsbyImage } from "gatsby-plugin-image"
import Video from "src/components/Video"
import TextLockup from "src/components/TextLockup"
import ScrollEntrance from "src/components/ScrollEntrance"
import { mq, globals, typography, util, colors } from "src/styles"
import QuoteIconSvg from "src/assets/images/quote-icon.svg"
import TestimonialMedia from "src/components/TestimonialMedia"

const TestimonialLargeSection = styled(Section)`
  ${({ isLastSection, setTheme, nextTheme }) =>
    isLastSection &&
    nextTheme &&
    nextTheme !== setTheme &&
    `
    ${globals.verticalSpacing("margin-bottom", 0.5)}
  `}
`

const ColumnWrapper = styled.div`
  h1,
  h2,
  h3 {
    max-width: 20em;
  }
  h4,
  h4 {
    max-width: 26em;
  }
  h6 {
    max-width: 16em;
  }
  p {
    max-width: 40em;
  }
`

const MediaItem = styled.div`
  overflow: hidden;
  ${util.responsiveStyles("border-radius", 50, 40, 30, 30)}
`

const QuoteText = styled(TextLockup)`
  p {
    ${typography.h4}
    ${mq.largeAndBelow} {
      ${typography.bodyExtraLarge}
    }
  }
`

const QuoteIcon = styled(ScrollEntrance)`
  svg {
    ${util.responsiveStyles("width", 60, 50, 40, 36)}
  }
`

const QuoteInfo = styled(ScrollEntrance)`
  p {
    ${typography.body}
    line-height: 1.3em;
    font-weight: ${typography.medium};
    margin: 0;
  }
`

const mediaSizes = {
  default: {
    normal: (100 / 28) * 11,
    large: (100 / 28) * 12,
    extraLarge: (100 / 28) * 13,
  },
}

const TestimonialLarge = ({
  item,
  theme,
  prevTheme,
  nextTheme,
  mediaPlacement = "left",
  mediaWidth = "normal",
  width = "default",
  isLastSection,
  isFirstSection,
  padded,
  index,
  testimonialsLength,
  onPlay,
  onPause,
  playVideo,
  autoPlay,
}) => {
  const image = item.media?.image?.asset
  const video = item.media?.video?.asset

  if (!image && !video) {
    return false
  }

  // set responsive image sizes
  let sizes = "100vw"
  let imageSize = mediaSizes.default[mediaWidth]
  sizes = "(min-width: " + mq.mediumBreakpoint + "px) " + imageSize + "vw, 86vw"

  const themeInverse = {
    default: "lightGrey",
    lightGrey: "default",
    darkBlue: "lightGrey",
  }

  const setTheme = index % 2 ? themeInverse[theme] : theme

  return (
    <TestimonialLargeSection
      setTheme={setTheme}
      prevTheme={index === 0 ? prevTheme : themeInverse[setTheme]}
      nextTheme={
        index === testimonialsLength - 1 ? nextTheme : themeInverse[setTheme]
      }
      isLastSection={isLastSection}
      isFirstSection={isFirstSection}
      padded={padded}
    >
      <Grid small="container" extraLarge="2 [10] 2">
        <Grid
          small="[1]"
          medium="[4] 1 [7]"
          large="[4] 1 [7]"
          larger="1 [3] 1 [7]"
          larger="1 [3] 2 [6]"
          rowGap={["4vw", "4vw", "80px"]}
          vAlign="center"
          gridDirection={index % 2 ? "rtl" : "ltr"}
        >
          {item.media && (
            <TestimonialMedia
              onPlay={onPlay}
              onPause={onPause}
              index={index}
              media={item.media}
              playVideo={playVideo}
              autoPlay={autoPlay}
              alt={item.name + ", " + item.subtitle}
            />
          )}

          <ColumnWrapper>
            <Grid small="[1]" large="[1]">
              <div>
                <QuoteIcon>
                  <QuoteIconSvg />
                </QuoteIcon>
                <QuoteText
                  entranceDelay={1}
                  text={item.text}
                  theme={setTheme}
                />
                <QuoteInfo delay={3}>
                  <p>{item.name}</p>
                  <p style={{ color: colors.lightTextColor }}>
                    {item.subtitle}
                  </p>
                </QuoteInfo>
              </div>
            </Grid>
          </ColumnWrapper>
        </Grid>
      </Grid>
    </TestimonialLargeSection>
  )
}

TestimonialLarge.defaultProps = {
  mediaWidth: "normal",
  mediaPlacement: "left",
}

export default TestimonialLarge
