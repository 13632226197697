import React from 'react'
import TextSection from 'src/components/TextSection'
import WideMedia from 'src/components/WideMedia'
import FiftyFifty from 'src/components/FiftyFifty'
import Columns from 'src/components/Columns'
import TwoColText from 'src/components/TwoColText'
import TestimonialList from 'src/components/TestimonialList'
import ContactForm from 'src/components/ContactForm'
import SmartsheetForm from 'src/components/SmartsheetForm'
import MailchimpForm from 'src/components/MailchimpForm'
import LocationsList from 'src/components/LocationsList'
import TeamGrid from 'src/components/TeamGrid'
import HiringThing from 'src/components/HiringThing'

const componentMap = {
  textSection: TextSection,
  wideMedia: WideMedia,
  fiftyFifty: FiftyFifty,
  columns: Columns,
  twoColText: TwoColText,
  testimonialsList: TestimonialList,
  contactForm: ContactForm,
  smartsheetForm: SmartsheetForm,
  mailchimpForm: MailchimpForm,
  locationsList: LocationsList,
  teamGrid: TeamGrid,
  jobsListing: HiringThing
}

const ComponentRenderer = ({
  item,
  prevTheme,
  nextTheme,
  index,
  isLastSection,
  isFirstSection,
}) => {
  if (!item || !item?._type) {
    return false
  }
  const Component = componentMap[item._type]
  if (!Component) {
    return false
  }

  return Component ? (
    <Component
      {...item}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      isLastSection={isLastSection}
      isFirstSection={isFirstSection}
      index={index}
    />
  ) : null
}

export default ComponentRenderer
