/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { darken } from 'polished'
import TextareaAutosize from 'react-textarea-autosize'
import { inputThemes as themes } from 'src/styles/themes'
import { baseBorderRadius, uiElementSizes } from 'src/styles/globals'
import { colors, typography, animations, util } from 'src/styles'
import MaterialIcon from 'src/components/MaterialIcon'
import { isEmoji } from 'src/utils/validations'

export const inputVars = {
	tiny: uiElementSizes.tiny,
	small: uiElementSizes.small,
	medium: uiElementSizes.medium,
	large: uiElementSizes.large,
	borderWidth: 1,
	backgroundColor: 'transparent',
	borderRadius: baseBorderRadius,
}

export const setInputTheme = (theme) => `
		color: ${themes[theme].color};
		textarea, select, .mock-input {
			background: ${themes[theme].background};
			border-color: ${themes[theme].borderColor};
			caret-color: ${themes[theme].color};
			color: ${themes[theme].color};
			&:active,
			&:focus,
			&:active:hover,
			&:focus:hover {
				background: ${darken(0.05, themes[theme].focusBackground)};
				border-color: ${themes[theme].focusBorderColor};
			}
			&:hover {
				background: ${darken(0.05, themes[theme].hoverBackground)};
				border-color: ${themes[theme].hoverBorderColor};
			}
			&:-webkit-autofill {
				transition: background-color 5000s ease-in-out 0s;
				-webkit-text-fill-color: ${themes[theme].color} !important;
				color: ${themes[theme].color} !important;
			}
			&:-internal-autofill-selected,
			&:-webkit-autofill,
			&:-internal-autofill:hover,
			&:-webkit-autofill:focus {
				background: ${darken(0.05, themes[theme].background)} !important;
				border-color: ${themes[theme].borderColor};
				-webkit-text-fill-color: ${themes[theme].color} !important;
				color: ${themes[theme].color} !important;
			}
			::placeholder {
				color: ${themes[theme].placeholderColor};
			}
		}
	`

export const getState = (loading, error, success, disabled) => {
	let buttonState = ''
	if (error) {
		buttonState = 'error'
	} else if (loading) {
		buttonState = 'loading'
	} else if (success) {
		buttonState = 'success'
	} else if (disabled) {
		buttonState = 'disabled'
	}

	return buttonState
}

export const TextAreaWrap = styled.div`
	position: relative;
	display: inline-block;
	width: 100%;
	${typography.bodySmall}
	font-weight: ${typography.normal};
	${({ theme }) => setInputTheme(theme)}
`

export const TextAreaStyles = (state, size, icon, iconPosition, theme, label) => `
	resize: none;
  appearance: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  outline: none;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  background: ${inputVars.backgroundColor};
  border: ${`${inputVars.borderWidth}px`} solid;
  height: ${inputVars[size] * 2}px;
  min-height: ${inputVars[size] * 2}px;
  line-height: 1.5em;
  text-transform: inherit;
  letter-spacing: 0;
  border-radius: ${inputVars.borderRadius}px;
  color: inherit;
  font-style: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  text-align: left;
  box-shadow: none;
  padding: 0 ${`${inputVars[size] * 0.3}px`};
  ${util.responsiveStyles('padding-top', inputVars[size] * 0.285 * 1.3, inputVars[size] * 0.285, inputVars[size] * 0.285, inputVars[size] * 0.285)}
  ${util.responsiveStyles('padding-bottom', inputVars[size] * 0.285 * 1.3, inputVars[size] * 0.285, inputVars[size] * 0.285, inputVars[size] * 0.285)}
  ${
		icon
			? `
  	${util.responsiveStyles(`padding-${iconPosition}`, inputVars[size] * 1.3, inputVars[size], inputVars[size], inputVars[size])}
	`
			: ''
	}
  ${util.fontSmoothing}
  transition: background ${animations.mediumSpeed} ease-in-out,
              color ${animations.mediumSpeed} ease-in-out,
              border ${animations.mediumSpeed} ease-in-out,
              box-shadow ${animations.mediumSpeed} ease-in-out,
              transform ${animations.mediumSpeed} ease-in-out,
              opacity ${animations.mediumSpeed} ease-in-out;
	// Input States
	::placeholder {
		color: ${colors.lightTextColor};
	}
	${state === 'disabled' ? 'cursor: not-allowed;' : ''}
	${state === 'loading' ? 'cursor: wait;' : ''}
	${
		state === 'error'
			? `
		border-color: ${colors.alert};
	`
			: ''
	}
	${
		label
			? `
			${util.responsiveStyles('padding-top', inputVars[size] * 0.425 * 1.3, inputVars[size] * 0.425, inputVars[size] * 0.425, inputVars[size] * 0.425)}
		  ${util.responsiveStyles('padding-bottom', inputVars[size] * 0.16 * 1.3, inputVars[size] * 0.16, inputVars[size] * 0.16, inputVars[size] * 0.16)}
		`
			: ''
	}
`

const StyledTextarea = styled(TextareaAutosize)`
	${({ loading, error, success, disabled, size, icon, iconPosition, label, theme }) =>
		TextAreaStyles(getState(loading, error, success, disabled), size, icon, iconPosition, theme, label)}
`

export const TextAreaIcon = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	pointer-events: none;
	overflow: hidden;
	${({ emojiIcon }) =>
		emojiIcon &&
		`
		padding-top: .3em;
		font-size: 18px;
		line-height: 1em;
	`}
	${({ iconPosition }) => `${iconPosition}: ${inputVars.borderWidth}px;`}
	${({ size }) => `
		${util.responsiveStyles('width', inputVars[size] * 1.3, inputVars[size], inputVars[size], inputVars[size])}
		${util.responsiveStyles('height', inputVars[size] * 1.3, inputVars[size], inputVars[size], inputVars[size])}
	`}
	span, svg {
		display: block;
	}
`

export const TextAreaLabel = styled.label`
	position: absolute;
	font-style: inherit;
	font-size: inherit;
	font-family: inherit;
	font-weight: inherit;
	line-height: inherit;
	top: 0;
	left: ${`${inputVars.borderWidth}px`};
	${({ size }) => util.responsiveStyles('height', inputVars[size] * 1.3, inputVars[size], inputVars[size], inputVars[size])}
	display: flex;
	align-items: center;
	pointer-events: none;
	margin: 0 ${({ size }) => `${inputVars[size] * 0.3}px`};
	color: ${({ error }) => (error ? `${colors.alert}` : 'inherit')};
	transition: transform ${animations.mediumSpeed} ease-in-out, color ${animations.mediumSpeed} ease-in-out;
	transform-origin: 0% 50%;
	${(props) =>
		props.placeholder || props.value || props.focused
			? `
		transform: translate3d(0, -10px, 0) scale(.75);
	`
			: ''}
	${(props) =>
		props.focused
			? `
		color: ${themes[props.theme].accentColor};
		font-weight: ${ typography.medium };
	`
			: ''}
	${({ icon, iconPosition, size }) =>
		icon
			? `
		${util.responsiveStyles('height', inputVars[size] * 1.3, inputVars[size], inputVars[size], inputVars[size])}
		${util.responsiveStyles(`margin-${iconPosition}`, inputVars[size] * 1.3, inputVars[size], inputVars[size], inputVars[size])}
	`
			: ''}
`

const TextArea = ({
	value,
	type = 'text',
	icon,
	iconPosition = 'left',
	loading,
	error,
	success,
	disabled,
	onClick,
	theme = 'default',
	setTheme = false,
	className,
	shape,
	size = 'medium',
	placeholder,
	label,
	spellcheck = false,
	name,
	onChange = () => {},
}) => {
	const [focused, setFocus] = useState(false)

	const renderIcon = (icon, size, iconPosition, theme) => {
		let renderedIcon = false
		if (typeof icon === 'string') {
			const isEmojiIcon = isEmoji(icon)
			if (isEmojiIcon) {
				renderedIcon = (
					<TextAreaIcon size={size} iconPosition={iconPosition} theme={theme} emojiIcon>
						{icon}
					</TextAreaIcon>
				)
			} else {
				// eslint-disable-next-line react/destructuring-assignment
				renderedIcon = (
					<TextAreaIcon size={size} iconPosition={iconPosition} theme={theme}>
						<MaterialIcon size={size === 'tiny' && '18px'}>{icon}</MaterialIcon>
					</TextAreaIcon>
				)
			}
		} else {
			renderedIcon = (
				<TextAreaIcon size={size} iconPosition={iconPosition} theme={theme}>
					{icon}
				</TextAreaIcon>
			)
		}
		return renderedIcon
	}

	return (
		<TextAreaWrap className={className} theme={setTheme || theme}>
			<StyledTextarea
				type={type}
				placeholder={placeholder}
				icon={icon}
				iconPosition={iconPosition}
				loading={loading}
				error={error}
				success={success}
				disabled={disabled}
				onClick={onClick}
				theme={setTheme || theme}
				shape={shape}
				size={size}
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)} // needs work
				onChange={onChange}
				value={value}
				label={label}
				name={name}
				id={name}
				spellCheck={spellcheck}
			/>
			{label && (
				<TextAreaLabel
					icon={icon}
					iconPosition={iconPosition}
					size={size}
					error={error}
					theme={setTheme || theme}
					value={value}
					htmlFor={name}
					focused={focused}
					placeholder={placeholder}
					className={placeholder || value || focused ? 'focused' : 'unfocused' /* to select from styled component */}
				>
					{label}
				</TextAreaLabel>
			)}
			{icon && renderIcon(icon, size, iconPosition, theme)}
		</TextAreaWrap>
	)
}

export default TextArea
