import React from 'react'
import PropTypes from 'prop-types'

const MailchimpForm = ({
  embedCode
}) => {

  return embedCode.includes('mailchimp.com') && embedCode.includes('list-manage.com') && <div style={{ display: 'flex', justifyContent: 'center' }} dangerouslySetInnerHTML={{ __html: embedCode }} />
}

MailchimpForm.propTypes = {
  title: PropTypes.string,
  sheetId: PropTypes.string,
  mediaWidth: 'normal',
  mediaPlacement: 'left'
}

export default MailchimpForm
