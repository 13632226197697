import React, { useState, useContext, useEffect, useRef, Fragment } from "react"
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  useGoogleMap,
} from "@react-google-maps/api"

// import mapStyle from 'components/Stockists/mapStyle.js';
// import StockistCard from './components/StockistCard.js';
import MapPin from "../../../static/map-pin.svg"

// import { FinderContext } from 'store/FinderState';

const libraries = ["places"]

const Map = (props) => {
  const [map, setMap] = useState(null)
  const [selected, setSelected] = useState(null)
  const [center, setCenter] = useState({ lat: 36.2679, lng: -112.3535 })
  const [zoom, setZoom] = useState(4.325)

  const markerRefs = useRef([])

  useEffect(() => {
    if (selected) {
      setCenter(selected.location.location)
      setZoom(8)
    }
  }, [selected])

  const fitBounds = (map) => {
    // console.log('fitBounds method called')
    // if ( finder.stockists.length > 0 ){
    //    const bounds = new window.google.maps.LatLngBounds();
    //    finder.stockists.map(place => {
    //     let placeLatLng = new google.maps.LatLng(place.fields.location.lat, place.fields.location.lon);
    //      bounds.extend(placeLatLng);
    //    });
    //    setMapBounds(bounds);
    //    // map.fitBounds(mapBounds);
    //    console.log('mapBounds', mapBounds)
    // }
  }

  const handleMapLoad = (map) => {
    setMap(map)
    // fitBounds(map)
    // finder.stockists.length > 1 ? fitBounds(map) : false;
    // map.fitBounds(mapBounds);
  }

  return (
    <LoadScript
      googleMapsApiKey={process.env.GATSBY_GOOGLE_API_KEY}
      mapIds={["ebd34ca159d55bd1"]}
    >
      <GoogleMap
        className={props.className}
        id="finder-google-map"
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        zoom={zoom}
        // center={props.pins[1]}
        center={center}
        onLoad={handleMapLoad}
        // onDragEnd={()=> onMapChange(map)}
        // onZoomChanged={()=> onMapChange(map)}
        options={{
          // styles: mapStyle.monopolio,
          disableDefaultUI: true,
          // zoomControl: props.mobile ? false : true,
          zoomControl: true,
          mapTypeControl: false,
          // scaleControl: true,
          scaleControl: false,
          minZoom: 1,
          mapId: "ebd34ca159d55bd1",
        }}
        // ref={(map) => { console.log(map); map.fitBounds(mapBounds); }}
      >
        {props.pins.map((item, index) => {
          return (
            <Fragment key={item._key}>
              <Marker
                ref={(node) => (markerRefs.current[item._key] = node)}
                id={item._key}
                position={{ lat: item.lat, lng: item.lng }}
                onClick={() => setSelected(item)}
                // onClick={() => handleMarkerClick(stockist)}
                // stockist={stockist}
                icon={{
                  url: MapPin,
                }}
              >
                {selected?.location.location === item.location.location && (
                  <InfoWindow onCloseClick={() => setSelected()}>
                    {selected.infoWindow}
                  </InfoWindow>
                )}
              </Marker>
              {/* isOpen && finder.selectedGooglePlace && !mobile && <InfoWindow
                      position={{lat : activeMarker.fields.location.lat, lng : activeMarker.fields.location.lon}}
                      onCloseClick={handleInfoWindowClose}
                      options={infoOffset ? infoOptions : null}
                    >
                      <StockistCard
                        stockist={activeMarker}
                        selectedGooglePlace={finder.selectedGooglePlace}
                        lang={props.locale}
                        loadingGooglePlace={props.loadingGooglePlace}
                      />

                    </InfoWindow>
                */}
            </Fragment>
          )
        })}
      </GoogleMap>
    </LoadScript>
  )
}

export default Map
