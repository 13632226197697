import React from "react"
import styled from "@emotion/styled"

import { typography, colors, util, animations, mq } from "src/styles"

const Wrapper = styled.div`
	display: block;
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	label {
		cursor: pointer;
		margin-left: 24px;
		line-height: 18px;
	}

	:hover input ~ .checkmark {
		background: ${colors.grey3};
	}

	input:checked ~ .checkmark {
		background: ${colors.mainColor};
	}

	input:checked ~ .checkmark:after {
		display: block;
	}
`

const Checkmark = styled.div`
	transition: background 200ms ease-in-out;
	position: absolute;
	top: 6px;
	left: 0;
	height: 18px;
	width: 18px;
	background: ${colors.grey2};
	border-radius: 50%;

	&:after {
		content: "";
		position: absolute;
		display: none;
		top: 5px;
		left: 5px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		transition: background 200ms ease-in-out;

		background: ${colors.white};
	}
`

const StyledInput = styled.input`
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
`

class Radio extends React.Component {
	state = {}

	handleChange = (event) => {
		this.props.onChange && this.props.onChange(event)
	}

	render() {
		const { className, children, name, id, checked, value } = this.props
		return (
			<Wrapper className={className}>
				<label htmlFor={id}>
					{children}
					<StyledInput
						type="radio"
						name={name}
						value={value}
						id={id}
						checked={checked}
						onChange={this.handleChange}
					/>
					<Checkmark className="checkmark" />
				</label>
			</Wrapper>
		)
	}
}

export default Radio
