import React from "react"
import styled from "@emotion/styled"
import Grid from "src/components/Grid"
import { GatsbyImage } from "gatsby-plugin-image"
import Video from "src/components/Video"
import TextLockup from "src/components/TextLockup"
import TestimonialMedia from "src/components/TestimonialMedia"
import { mq, typography, util, colors } from "src/styles"

const Wrapper = styled.div`
  text-align: center;
`

const ColumnWrapper = styled.div`
  h1,
  h2,
  h3 {
    max-width: 20em;
  }
  h4,
  h4 {
    max-width: 26em;
  }
  h6 {
    max-width: 16em;
  }
  p {
    max-width: 40em;
  }
`

const MediaItem = styled(TestimonialMedia)`
  width: 75%;
  margin: 0 auto 5%;
`

const QuoteText = styled(TextLockup)`
  p {
    ${typography.bodyExtraLarge}
  }
`

const QuoteInfo = styled.div`
  p {
    ${typography.bodySmall}
    margin: 0 auto;
    line-height: 1.3em;
    font-weight: ${typography.medium};
  }
`

const mediaSizes = {
  default: {
    normal: (100 / 28) * 11,
    large: (100 / 28) * 12,
    extraLarge: (100 / 28) * 13,
  },
}

const TestimonialSmall = ({
  item,
  theme,
  prevTheme,
  nextTheme,
  mediaPlacement = "left",
  mediaWidth = "normal",
  width = "default",
  isLastSection,
  isFirstSection,
  padded,
  onPlay,
  onPause,
  playVideo,
  autoPlay,
  index,
  testimonialsLength,
}) => {
  const image = item.media?.image?.asset
  const video = item.media?.video?.asset

  if (!image && !video) {
    return false
  }

  // set responsive image sizes
  let sizes = "100vw"
  let imageSize = mediaSizes.default[mediaWidth]
  sizes = "(min-width: " + mq.mediumBreakpoint + "px) " + imageSize + "vw, 86vw"

  const themeInverse = {
    default: "lightGrey",
    lightGrey: "default",
    darkBlue: "lightGrey",
  }

  const setTheme = index % 2 ? themeInverse[theme] : theme

  return (
    <Wrapper isLastSection={isLastSection} isFirstSection={isFirstSection}>
      <div>
        {item.media && (
          <MediaItem
            onPlay={onPlay}
            onPause={onPause}
            playVideo={playVideo}
            autoPlay={autoPlay}
            index={index}
            media={item.media}
            alt={item.name + ", " + item.subtitle}
          />
        )}

        <ColumnWrapper>
          <Grid small="[1]" large="[1]">
            <div>
              <QuoteText text={item.text} theme={setTheme} alignment="center" />
              <QuoteInfo delay={1}>
                <p>{item.name}</p>
                <p style={{ color: colors.lightTextColor }}>{item.subtitle}</p>
              </QuoteInfo>
            </div>
          </Grid>
        </ColumnWrapper>
      </div>
    </Wrapper>
  )
}

TestimonialSmall.defaultProps = {
  mediaWidth: "normal",
  mediaPlacement: "left",
}

export default TestimonialSmall
