import React, { Component } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Grid from "src/components/Grid"
import Input from "src/components/Input"
import TextArea from "src/components/TextArea"
import TextLockup from "src/components/TextLockup"
import Button from "src/components/Button"
import ScrollEntrance from "src/components/ScrollEntrance"
import { util } from "src/styles"
import { validateEmail, validatePhoneNumber } from "src/utils/validations"
import { withAppState } from "src/state/AppState"

const FormSection = styled.div`
  text-align: center;
  width: 90vw;
  ${util.responsiveStyles("max-width", 800, 600, 600, 600)}
  ${util.responsiveStyles("padding-top", 60, 60, 40, 30)}
  ${util.responsiveStyles("padding-bottom", 80, 80, 50, 40)}
  ${util.responsiveStyles("padding-left", 80, 80, 50, 30)}
  ${util.responsiveStyles("padding-right", 80, 80, 50, 30)}
`

const FormIntro = styled(TextLockup)`
  ${util.responsiveStyles("margin-bottom", 30, 30, 24, 24)}
  p {
    max-width: 16em;
    margin-left: auto;
    margin-right: auto;
  }
`

class PartnerForm extends Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: "",
      firstName: "",
      lastName: "",
      practice: "",
      practiceLocation: "",
      email: "",
      phone: "",
      message: "",
    }
  }

  handleInput = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const {
      className,
      theme,
      prevTheme,
      nextTheme,
      isFirstSection,
      appContext,
    } = this.props
    const formSpacing = "14px"

    const { toggleModal } = appContext

    const {
      status,
      firstName,
      lastName,
      email,
      phone,
      practice,
      practiceLocation,
      message,
    } = this.state

    const valid =
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      phone !== "" &&
      practice !== "" &&
      practiceLocation !== "" &&
      validateEmail(email) &&
      validatePhoneNumber(phone)
    return (
      <FormSection
        className={className}
        prevTheme={prevTheme}
        nextTheme={nextTheme}
        setTheme={theme}
        isFirstSection={isFirstSection}
      >
        <Button
          css={css`
            position: absolute;
            top: 0;
            right: 0;
          `}
          size="small"
          shape="circle"
          icon="close"
          onClick={() => toggleModal(false)}
          setTheme="transparent"
        />
        <FormIntro
          headline="Become a Partner"
          text="Fill out your info below and we will be in touch about working together."
        />
        <div>
          <form
            onSubmit={this.submitForm}
            action="https://formspree.io/mrgroykw"
            method="POST"
          >
            <Grid small="[1]" rowGap={formSpacing} as={ScrollEntrance}>
              <div>
                <Input
                  onChange={this.handleInput}
                  name="firstName"
                  value={firstName}
                  label="First Name"
                />
              </div>
              <div>
                <Input
                  onChange={this.handleInput}
                  name="lastName"
                  value={lastName}
                  label="Last Name"
                />
              </div>
              <div>
                <Input
                  onChange={this.handleInput}
                  name="practice"
                  value={practice}
                  label="Practice"
                />
              </div>
              <div>
                <Input
                  onChange={this.handleInput}
                  name="practiceLocation"
                  value={practiceLocation}
                  label="Practice Location"
                />
              </div>
              <div>
                <Input
                  onChange={this.handleInput}
                  name="email"
                  value={email}
                  label="Email"
                  type="email"
                  isValid={email !== "" ? validateEmail(email) : true}
                />
              </div>
              <div>
                <Input
                  onChange={this.handleInput}
                  name="phone"
                  value={phone}
                  label="Phone"
                  type="tel"
                  isValid={phone !== "" ? validatePhoneNumber(phone) : true}
                />
              </div>
              <div>
                <TextArea
                  onChange={this.handleInput}
                  name="message"
                  value={message}
                  label="Tell us more"
                />
              </div>
              <div style={{ paddingTop: formSpacing }}>
                <Button type="submit" setTheme="darkBlue" disabled={!valid}>
                  {status === "SUCCESS" ? "Thank You" : "Submit"}
                </Button>
              </div>
            </Grid>
          </form>
        </div>
      </FormSection>
    )
  }

  submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        this.setState({ status: "SUCCESS" })
        setTimeout(() => {
          this.setState({
            status: "",
            firstName: "",
            lastName: "",
            practice: "",
            practiceLocation: "",
            email: "",
            phone: "",
            message: "",
          })
          form.reset()
        }, 2000)
      } else {
        this.setState({ status: "ERROR" })
      }
    }
    xhr.send(data)
  }
}

PartnerForm.defaultProps = {
  mediaWidth: "normal",
  mediaPlacement: "left",
}

export default withAppState(PartnerForm)
