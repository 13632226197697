import React from 'react'
import styled from '@emotion/styled'
import LogoSvg from 'src/assets/images/logo.svg'
import LogomarkSvg from 'src/assets/images/logomark.svg'

const LogoWrapper = styled.div`
	height: auto;
	display: inline-block;
	vertical-align: top;
	.lockup-mark {
		transition: opacity .3s ease-in-out;
		${ ({ collapsed }) => collapsed ? `
			opacity: 0;
		` : `` }
	}
	svg {
		display: inline-block;
		vertical-align: top;
		width: 100%;
		height: auto;
		color: inherit;
		transition: transform .3s ease-in-out;
		${ ({ collapsed }) => collapsed ? `
			transform: translateY(-22%);
		` : `` }
		* {
			fill: currentcolor;
		}
	}
`

const Logo = ({ className, collapsed }) => (
	<LogoWrapper className={className} collapsed={collapsed}>
		<LogoSvg />
	</LogoWrapper>
)

export const Logomark = ({ className }) => (
	<LogoWrapper className={className}>
		<LogomarkSvg />
	</LogoWrapper>
)

export default Logo
